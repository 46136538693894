const affirmations = [
  'Finer than frog hair!',
  "That's great!",
  'Good job!',
  'Way to go!',
  'Sensational!',
  "You're the bee's knees!",
  'I knew you could do it!',
  "That's the way you do it!",
  'Classic!',
  'Well done!',
  'Slicker than snot on a doorknob!',
  'Nicely done!',
  'Outta sight!',
  'Superb!',
  'All right!',
  'Great going!',
  'Great job!',
  'Excellent work!',
  'Niiiiiice!',
];

export default affirmations;
